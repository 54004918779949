import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "140px 0 140px 0",
	"sm-padding": "40px 0 40px 0",
	"background": "linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://images.unsplash.com/photo-1521677446241-d182a96ec49f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover",
	"color": "--light",
	"font": "--base"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"margin": "0 0 96px 0",
			"text-align": "center",
			"letter-spacing": "20px",
			"text-transform": "uppercase",
			"font": "--headline3",
			"lg-font": "200 42px/1.2 --fontFamily-sans",
			"lg-letter-spacing": "20px",
			"children": "Welcome to Coffee Haven"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "-16px -16px -16px -16px",
			"flex-wrap": "wrap",
			"width": "100%",
			"justify-content": "space-between"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "16px 16px 16px 16px",
			"width": "25%",
			"lg-width": "50%",
			"sm-width": "100%"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px",
			"font": "--base",
			"text-transform": "uppercase",
			"children": "your perfect daily escape where the aroma of freshly brewed coffee blends with a cozy atmosphere."
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "16px 16px 16px 16px",
			"width": "25%",
			"lg-width": "50%",
			"sm-width": "100%"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px",
			"children": "Whether you're starting your day, taking a break, or winding down, our coffee shop offers the perfect setting to relax and rejuvenate."
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" sm-align-items="center" />
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text1")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Text {...override("text2")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;